jQuery(document).ready(function($) {

    const $headerNav = $('#header-nav');
    const $headerNavDropdowns = $('#header-nav-dropdowns');
    const dropdownData = $headerNavDropdowns.data('content');
    let dropdownCount = 1;

    $('#header-nav > ul > li.menu-item-has-children').each(function() {
        let dropdownHTML = '';
        let dropdownSubmenu = $('> .sub-menu', $(this)).html();
        let dropdownClasses = '';

        $('> .sub-menu', $(this)).remove();

        if(dropdownData.hasOwnProperty(dropdownCount) && dropdownData[dropdownCount].hasOwnProperty('left_content')) {
            dropdownHTML = dropdownHTML + '<div class="dropdown__left">' + dropdownData[dropdownCount]['left_content'] + '</div>';
        } else {
            dropdownClasses = dropdownClasses + ' --no-left';
        }

        dropdownHTML = dropdownHTML + '<ul class="dropdown__submenu">' + dropdownSubmenu + '</ul>';

        if(dropdownData.hasOwnProperty(dropdownCount) && dropdownData[dropdownCount].hasOwnProperty('right_content')) {
            dropdownHTML = dropdownHTML + '<div class="dropdown__right">' + dropdownData[dropdownCount]['right_content'] + '</div>';
        } else {
            dropdownClasses = dropdownClasses + ' --no-right';
        }

        dropdownHTML = '<div id="header-nav-dropdown-' + dropdownCount + '" class="dropdown' + dropdownClasses + '">' + dropdownHTML + '</div>';

    	$(this).prepend(dropdownHTML);

        dropdownCount++;
    });

    $('.dropdown > .dropdown__submenu > .menu-item:first-child', $headerNav).each(function() {
        $(this).addClass('--active');
    });

    $('.dropdown > .dropdown__submenu > .menu-item', $headerNav).on('mouseover', function() {
        $('.dropdown > .dropdown__submenu > .menu-item.--active', $headerNav).removeClass('--active');
        $(this).addClass('--active');
    });
 
});